.LsB2h {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
}

.LsB2h::-webkit-scrollbar {
  display: none;
}

.LsB2h .RWcIP {
  z-index: 10;
  cursor: pointer;
  background: var(--grey-sur);
  border-radius: 20px;
  width: 4px;
  height: calc(100% - 8px);
  transition: all .1s;
  position: absolute;
  top: 4px;
  bottom: 0;
  right: 0;
}

.LsB2h .RWcIP:hover {
  background: #0003;
  width: 8px !important;
}

.LsB2h .RWcIP:hover .kf3pr {
  background: #00000080;
}

.LsB2h .dQA8W {
  -webkit-user-select: none;
  user-select: none;
}

.LsB2h .kf3pr {
  z-index: 0;
  cursor: grab;
  background: #0000006b;
  border-radius: 20px;
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
}

.LsB2h .kf3pr:hover {
  background: #0009;
}

.LsB2h .kf3pr.nDSm1 {
  cursor: grab;
  background: #00000080;
}

.LsB2h .nDSm1 {
  cursor: grab;
  -webkit-user-select: none;
  user-select: none;
  background: #0003;
  width: 8px !important;
}

.MifWe {
  z-index: 2147483647;
  pointer-events: none;
  background: var(--white);
  opacity: 0;
  border-radius: 10px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 11px;
  position: fixed;
  box-shadow: 0 0 0 1px #00000008, 0 0 20px #0003;
}

.MifWe .ffBJp {
  width: 10px;
  height: 10px;
  position: absolute;
}

.MifWe .ffBJp:after {
  content: "";
  background-color: var(--white);
  width: 10px;
  height: 10px;
  position: absolute;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 0 4px 14px #0003, 0 0 0 1px #0000000d;
}

.MifWe .zELri {
  box-sizing: border-box;
  background-color: var(--white);
  color: #222;
  z-index: 2;
  text-align: center;
  border-radius: 10px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

.zuPEc {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  margin-top: -6px;
  display: flex;
}

.zuPEc .zHhZo {
  background: var(--grey-sur);
  border-radius: 10px;
  width: 40%;
  height: 5px;
  left: 30%;
}

.-cgS8 {
  max-height: calc(100% - 6px - env(safe-area-inset-bottom)  - env(safe-area-inset-top)) !important;
  padding-bottom: env(safe-area-inset-bottom) !important;
}

.pcr-app {
  z-index: 99999999 !important;
  border-radius: 12px !important;
  transition: all !important;
  box-shadow: 0 0 0 1px #00000008, 0 0 20px #0003 !important;
}

.pcr-app .pcr-palette, .pcr-app .pcr-palette:before {
  border-radius: 8px !important;
}

.pcr-app .pcr-result {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  border-radius: 8px !important;
}

.pcr-app .pcr-result:focus {
  cursor: pointer;
  box-shadow: var(--grey-sur) 0 0 0 2px !important;
}

.pcr-app .pcr-swatches button, .pcr-app .pcr-swatches button:after {
  border-radius: 100% !important;
}

.pcr-app .pcr-swatches button:before {
  background: unset !important;
}

.apexcharts-legend {
  left: -9px !important;
}

.apexcharts-tooltip {
  background: var(--white);
  border-radius: 14px;
  box-shadow: 0 0 0 1px #00000008, 0 0 20px #0003;
  border: unset !important;
  background: var(--white) !important;
  border-radius: 12px !important;
  padding: 8px !important;
  box-shadow: 0 0 0 1px #00000008, 0 0 20px #0003 !important;
}

.apexcharts-tooltip .tooltip-content .side .value {
  border-bottom: 1px solid var(--grey-sur);
  gap: 6px;
  width: 100%;
  padding: 6px 0;
  font-size: 14px;
  display: flex;
}

.apexcharts-tooltip .tooltip-content .side .value .color {
  border-radius: 5px;
  width: 12px;
  height: 12px;
  margin-top: 3px;
}

.apexcharts-tooltip .tooltip-content .side .data {
  margin-left: auto;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 15px;
}

.apexcharts-tooltip .tooltip-content .date {
  color: var(--black);
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 17px;
}

.apexcharts-xaxistooltip {
  background: var(--white) !important;
  border: unset !important;
  border-radius: 10px !important;
  padding: 10px 16px !important;
  box-shadow: 0 0 0 1px #00000008, 0 0 20px #0003 !important;
}

.apexcharts-xaxistooltip:before {
  border-bottom-color: var(--grey-sur) !important;
}

.apexcharts-xaxistooltip:after {
  border-bottom-color: var(--white) !important;
}

.apexcharts-xaxistooltip .apexcharts-xaxistooltip-text {
  font-size: 14px !important;
}

.blot-formatter__overlay {
  border: 2px dashed #379fff !important;
  overflow: hidden !important;
}

.blot-formatter__overlay .ql-stroke {
  stroke: var(--black);
  stroke-width: 2px;
}

.blot-formatter__overlay .blot-formatter__toolbar {
  border-radius: 5px;
  margin: auto;
  overflow: hidden;
  box-shadow: 0 0 0 1px #00000008, 0 0 20px #0003;
  width: 72px !important;
  height: 24px !important;
  min-width: unset !important;
  top: 0 !important;
}

.blot-formatter__overlay .blot-formatter__toolbar .blot-formatter__toolbar-button {
  border: unset !important;
  filter: unset !important;
}

.blot-formatter__overlay .blot-formatter__toolbar .blot-formatter__toolbar-button.is-selected svg {
  background: var(--grey-sur) !important;
}

.blot-formatter__overlay .blot-formatter__toolbar .blot-formatter__toolbar-button svg {
  border: unset !important;
}

.blot-formatter__overlay .blot-formatter__resize-handle {
  opacity: 1 !important;
  border: unset !important;
  background: #379fff !important;
  border-radius: 2px !important;
  width: 8px !important;
  height: 8px !important;
}

.blot-formatter__overlay .blot-formatter__resize-handle[data-position="top-right"] {
  top: 1px !important;
  right: 1px !important;
}

.blot-formatter__overlay .blot-formatter__resize-handle[data-position="top-left"] {
  top: 1px !important;
  left: 1px !important;
}

.blot-formatter__overlay .blot-formatter__resize-handle[data-position="bottom-right"] {
  bottom: 1px !important;
  right: 1px !important;
}

.blot-formatter__overlay .blot-formatter__resize-handle[data-position="bottom-left"] {
  bottom: 1px !important;
  left: 1px !important;
}

@font-face {
  font-family: medium;
  src: local(Montserrat Medium), url("Montserrat-Medium.91612541.woff2") format("woff2"), url("Montserrat-Medium.bd19e0d4.woff") format("woff"), url("Montserrat-Medium.5425fc7a.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: bold;
  src: url("Montserrat-Bold.c78d159b.woff2") format("woff2"), url("Montserrat-Bold.f9ce8d83.woff") format("woff"), url("Montserrat-Bold.fc0d0bf0.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: black;
  src: local(Montserrat Black), url("Montserrat-Black.6d98d3fb.woff2") format("woff2"), url("Montserrat-Black.ddd143a0.woff") format("woff"), url("Montserrat-Black.2cab11ae.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: extra-bold;
  src: local(Montserrat ExtraBold), url("Montserrat-ExtraBold.9e6d4d9e.woff2") format("woff2"), url("Montserrat-ExtraBold.50c574e3.woff") format("woff"), url("Montserrat-ExtraBold.3ee63765.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: semi-bold;
  src: local(Montserrat SemiBold), url("Montserrat-SemiBold.9fe973e5.woff2") format("woff2"), url("Montserrat-SemiBold.bd204140.woff") format("woff"), url("Montserrat-SemiBold.0f16d814.ttf") format("truetype");
  font-display: swap;
}

:root {
  --grey-sur-light: #cfcfdb33;
  --grey-sur-opac: #efeff3;
  --grey-sur: #cfcfdb54;
  --grey-sur-hover: #cfcfdb73;
  --grey-sur-hover-opac: #e9e9ef;
  --grey-alpha: #0000001a;
  --grey-alpha-half: #0000000d;
  --grey-light: #cfcfdb33;
  --grey: #ebebeb;
  --grey-text: #84848a8a;
  --grey-text-dark: #84848a;
  --grey-text-dark-blog: #74747a;
  --grey-text-very-dark: #777;
  --grey-thumb: #888;
  --grey-dark: #dedede;
  --grey-extra-dark: #4a4a4a;
  --grey-shadow: #ffffffbd;
  --grey-mobile: #a0a7ac;
  --grey-gradient: #c8c8c8;
  --grey-transparent: #c4c4c400;
  --white: white;
  --white-light: white;
  --white-alpha: #ffffffd6;
  --white-transparent: #ffffff80;
  --white-sur: #ffffff1a;
  --white-sur-hover: #fff3;
  --black: black;
  --black-hr: #3f3939;
  --black-alpha: #00000080;
  --black-alpha-half: #0006;
  --black-alpha-two: #0003;
  --black-alpha-middle: #00000026;
  --black-alpha-max: #00000014;
  --black-alpha-dark: #000000b3;
  --black-light: #303235;
  --black-light-alpha: #32323280;
  --black-middle: #343434;
  --colors-1: #ffda2d;
  --colors-2: #febf00;
  --colors-3: #ffa501;
  --colors-4: #ff9100;
  --colors-5: #0b7598;
  --colors-6: #074f68;
  --red: #ef343e;
  --red-king: #611d1d;
  --red-dark: #dd2731;
  --red-hover: #f443361a;
  --red-alpha: #ef343e33;
  --red-pink: #fd3a57;
  --red-light: #fcd6d8;
  --red-very-light: #fff6f7;
  --red-real: #f0424a;
  --red-real-hover: #ff3e47;
  --red-flash: #ff3a3a;
  --red-notify: #e51010;
  --red-twitter: #f34f35;
  --purple-mep: #b453d8;
  --purple: #ba65fd;
  --purple-dark: #9551ca;
  --purple-extra: #6c42e4;
  --offer-free: linear-gradient(180deg, #41a2fc, #0085ff);
  --offer-free-hover: linear-gradient(180deg, #2594fc, #017ef0);
  --offer-paid: linear-gradient(180deg, #ffd12e, #ff981f);
  --offer-cancel: linear-gradient(180deg, #ff4c4c, #ff2020);
  --offer-advantage: #a0d1ff;
  --grad-sedestral: linear-gradient(to right, #434343 0%, black 100%);
  --grad-sedestral-inverse: linear-gradient(to top, #676767 0%, black 100%);
  --grad-inbox: linear-gradient(to top, #00c867, #14ff8d);
  --grad-help-center: linear-gradient(to top, #ff4e5b, #ffa2a2);
  --grad-blog: linear-gradient(to top, #9937dc, #d06bf9);
  --grad-statistics: linear-gradient(to top, #00f, #0084ff);
  --grad-sell: linear-gradient(to top, #d10c0b, #ff5851);
  --grad-contacts: linear-gradient(to top, #ff823d, #ffaf27);
  --grad-publisher: linear-gradient(to top, #006cff, #00c7ff);
  --grad-story: linear-gradient(143deg, #d399fa 0%, #8364e8 100%);
  --grad-green: linear-gradient(to bottom, #73ffa3, #00cf46);
  --grad-black: linear-gradient(to bottom, #000, #606060);
  --grad-purple: linear-gradient(to top, #c954ff, #af00ff);
  --grad-blue: linear-gradient(to bottom, #4886ff, #1967ff);
  --grad-blue-button: linear-gradient(to bottom, #41a2fc, #0085ff);
  --grad-red: linear-gradient(to bottom, #ff4c4c, #ff2020);
  --grad-orange-button: linear-gradient(to bottom, #ffd12e, #ff981f);
  --grad-orange-dark-button: linear-gradient(to bottom, #ffbf19, #ff981f);
  --grad-multi: linear-gradient(90deg, #ffa745, #ff4c4c, #c443ff, #4886ff, #4ffa40);
  --blue: #0197ff;
  --blue-mep: #1877f2;
  --blue-ai: #0dcaf8;
  --blue-mep-hover: #1163d0;
  --blue-twt: #1d9bf0;
  --blue-twt-black: #0e88da;
  --blue-hover: #3eb0ff;
  --blue-punk: #2541ff;
  --blue-pulp: #0093ee;
  --blue-dark: #4d63fb;
  --blue-alpha-dark-light: #00a6ed33;
  --blue-alpha-dark: #00a6ed1a;
  --blue-selection: #0089ff47;
  --blue-dark-hover: #ced6f6b3;
  --blue-light: #e3e7fa;
  --blue-ultra-light: #0197ff1a;
  --blue-cian: #e8f0fe;
  --blue-new: #f3fbff;
  --blue-new-text: #00b3ff;
  --blue-new-checked: #e4f7ff;
  --blue-new-hover: #dff5ff;
  --blue-str: #f7fafc;
  --blue-str-hover: #e5e9ec;
  --blue-str-hover-hover: #dde2e7;
  --blue-str-shadow: #e3e8ee;
  --blue-selection-shadow: #0084ff80;
  --blue-mobile: #0f77ff;
  --blue-pastel: #8fc2ff;
  --blue-twitter: #00b4fe;
  --green: #40db6f;
  --green-flash: #28d55c;
  --green-flash-hover: #26c857;
  --green-flash-alpha: #5ed9614d;
  --green-hover: #3ccb67;
  --green-dark: #18c23d;
  --green-dark-hover: #0d932d;
  --green-light: #e7ffe3;
  --green-light-dark: #d6f6d5;
  --green-very-light: #edfff4;
  --green-twitter: #00d040;
  --shadow-livechat: #00000014 0px 0px 0px 1px, #00000014 0px 2px 8px;
  --orange: #ffaf27;
  --orange-cover: #eca336;
  --orange-dark: #f08c00;
  --orange-light: #ffebc7;
  --orange-semi-light: #fff0d7;
  --orange-very-light: #fff7e9;
  --outline-blue: #0084ff80;
  --private: #fff2d7;
  --private-text: #ffa83e;
  --web-grad-blue: linear-gradient(180deg, #3974f1 0%, #3d54fc 100%);
  --web-border-blue: #ffffff78;
  --web-blue: #3a6ff3;
  --web-blue-hover: #1b50d3;
  --web-white-alpha: #ffffff1a;
  --web-white-alpha-hover: #ffffff38;
  --web-purple: #845bf1;
  --web-yellow: #fd0;
  --web-purple-text: #9360ff;
  --web-border-white: #ffffff2e;
  --web-background-steps: #a2bfff17;
  --web-brackground-grid-green: linear-gradient(180deg, #00e53200 0%, #00ff370f 100%);
  --web-background-grid-purple: linear-gradient(180deg, #8c62fe00 0%, #8c62fe0f 100%);
  --web-background-grid-blue: linear-gradient(180deg, #0000 0%, #0033ff17 100%);
  --web-background-grid-yellow: linear-gradient(180deg, #fd00 0%, #ffdd000f 100%);
  --web-background-grid-white: linear-gradient(180deg, #fff0 0%, #ffffff0f 100%);
  --web-text-white-alpha: #ffffff8c;
  --web-image-shadow: 0px 0px 30px 0px #0000001a;
  --web-shadow-green: #79ff44;
  --icon-black: invert(0%) sepia(96%) saturate(14%) hue-rotate(273deg) brightness(99%) contrast(104%);
  --icon-blue: invert(40%) sepia(84%) saturate(4291%) hue-rotate(224deg) brightness(98%) contrast(100%);
  --icon-blue-light: invert(50%) sepia(47%) saturate(2177%) hue-rotate(166deg) brightness(102%) contrast(103%);
  --icon-blue-mobile: invert(29%) sepia(74%) saturate(2619%) hue-rotate(208deg) brightness(106%) contrast(103%);
  --icon-blue-pulp: invert(35%) sepia(87%) saturate(2176%) hue-rotate(181deg) brightness(102%) contrast(101%);
  --icon-grey: invert(67%) sepia(24%) saturate(35%) hue-rotate(213deg) brightness(120%) contrast(109%);
  --icon-grey-dark: invert(84%) sepia(2%) saturate(16%) hue-rotate(314deg) brightness(99%) contrast(78%);
  --icon-grey-very-dark: invert(48%) sepia(0%) saturate(794%) hue-rotate(252deg) brightness(97%) contrast(95%);
  --icon-grey-extreme: invert(50%) sepia(19%) saturate(17%) hue-rotate(53deg) brightness(91%) contrast(91%);
  --icon-grey-mobile: invert(70%) sepia(10%) saturate(166%) hue-rotate(162deg) brightness(91%) contrast(98%);
  --icon-white: invert(100%) sepia(0%) saturate(2953%) hue-rotate(1deg) brightness(123%) contrast(102%);
  --icon-white-light: invert(100%) sepia(0%) saturate(2953%) hue-rotate(1deg) brightness(123%) contrast(102%) opacity(40%);
  --icon-red-pink: invert(36%) sepia(71%) saturate(3979%) hue-rotate(331deg) brightness(102%) contrast(98%);
  --icon-red: invert(47%) sepia(32%) saturate(6368%) hue-rotate(335deg) brightness(92%) contrast(109%);
  --icon-red-kind: invert(14%) sepia(50%) saturate(3265%) hue-rotate(316deg) brightness(95%) contrast(101%);
  --icon-green: invert(76%) sepia(16%) saturate(1827%) hue-rotate(81deg) brightness(94%) contrast(88%);
  --icon-green-flash: invert(89%) sepia(11%) saturate(5686%) hue-rotate(71deg) brightness(90%) contrast(84%);
  --icon-green-twitter: invert(62%) sepia(87%) saturate(4617%) hue-rotate(130deg) brightness(130%) contrast(101%);
  --icon-private: invert(85%) sepia(9%) saturate(7143%) hue-rotate(327deg) brightness(100%) contrast(104%);
  --icon-orange: invert(76%) sepia(49%) saturate(1509%) hue-rotate(337deg) brightness(103%) contrast(101%);
  --icon-orange-dark: brightness(0) saturate(100%) invert(55%) sepia(18%) saturate(5722%) hue-rotate(9deg) brightness(103%) contrast(101%);
  --icon-blue-black-web: invert(57%) sepia(81%) saturate(5709%) hue-rotate(210deg) brightness(98%) contrast(94%);
  --icon-purple-black-web: invert(55%) sepia(51%) saturate(7393%) hue-rotate(237deg) brightness(103%) contrast(100%);
}

.black {
  --grey-sur-opac: #38383e;
  --grey-sur: #3b435c;
  --grey-sur-hover: rgb(48, 48, 36 / 55%);
  --grey-sur-hover-opac: #e9e9ef;
  --grey-alpha: #ffffff1a;
  --grey-alpha-half: #ffffff0d;
  --grey-light: rgb(48, 48, 36 / 80%);
  --grey: #141414;
  --grey-text: #a6afc8;
  --grey-text-very-dark: #888;
  --grey-text-dark: #d4d4d4;
  --grey-text-dark-blog: #d4d4d4;
  --grey-thumb: #777;
  --grey-extra-dark: #b5b5b5;
  --grey-shadow: #00000042;
  --grey-mobile: #5f5853;
  --grey-gradient: #373737;
  --grey-transparent: #3b3b3b00;
  --white: #1f2333;
  --white-light: #3b435c;
  --white-alpha: #00000029;
  --black: #fff;
  --black-hr: #c0c6c6;
  --black-alpha: #ffffff80;
  --black-light: #cfcdca;
  --black-middle: #cbcbcb;
  --colors-1: #ff8a19;
  --colors-2: #fe5b00;
  --colors-3: #ff4101;
  --colors-4: #ff2d00;
  --colors-5: #0b394c;
  --colors-6: #072734;
  --red-dark: #d8202a;
  --red-hover: #0b62c91a;
  --red-alpha: #10cbc133;
  --red-pink: #fd1a37;
  --red-light: #672927;
  --red-very-light: #000908;
  --red-real: #0fbdb5;
  --red-real-hover: #00c1b8;
  --red-flash: #ff0a0a;
  --red-notify: #e55050;
  --red-twitter: #f31515;
  --purple-mep: #4a2d28;
  --purple-dark: #74ae35;
  --purple-extra: #9342b5;
  --blue: #b29c04;
  --blue-mep: #e7880d;
  --blue-mep-hover: #ee9c2f;
  --blue-twt: #e2a0b0;
  --blue-twt-black: #0e77ca;
  --blue-hover: #b14ff0;
  --blue-punk: #dabe00;
  --blue-pulp: #ff6c11;
  --blue-dark: #b29c04;
  --blue-alpha-dark-light: #ff591233;
  --blue-alpha-dark: #ff59121a;
  --blue-selection: #ff76ff47;
  --blue-dark-hover: #3129094d;
  --blue-light: #1c1805;
  --blue-ultra-light: #fe68001a;
  --blue-cian: #170f01;
  --blue-new: #0c0400;
  --blue-new-text: #ff4c00;
  --blue-new-checked: #1b0800;
  --blue-new-hover: #200a00;
  --blue-str: #080303;
  --blue-str-hover: #1a1717;
  --blue-str-hover-hover: #212121;
  --blue-str-shadow: #1d1c1c;
  --blue-selection-shadow: #ff7b0080;
  --blue-mobile: #f08800;
  --blue-pastel: #705dff;
  --blue-twitter: #0054fe;
  --green: #bf2480;
  --green-flash: #d72aa3;
  --green-flash-hover: #d937a8;
  --green-flash-alpha: #a1269e4d;
  --green-hover: #c32482;
  --green-dark: #e73dc2;
  --green-light: #18003c;
  --green-light-dark: #290049;
  --green-very-light: #310060;
  --green-twitter: #003520;
  --shadow-livechat: #ffffff14 0px 0px 0px 1px, #ffffff14 0px 2px 8px;
  --orange-dark: #0f73d8;
  --orange-light: #001438;
  --orange-semi-light: #000fb8;
  --orange-very-light: #0012f1;
  --outline-blue: #ff7b0080;
  --private: #000d28;
  --private-text: #ff5721;
  --icon-black: invert(100%);
  --icon-blue: invert(60%) sepia(84%) saturate(4291%) hue-rotate(224deg) brightness(98%) contrast(100%);
  --icon-blue-light: invert(50%) sepia(53%) saturate(783%) hue-rotate(194deg) brightness(98%) contrast(97%);
  --icon-blue-mobile: invert(71%) sepia(26%) saturate(1619%) hue-rotate(208deg) brightness(94%) contrast(97%);
  --icon-blue-pulp: invert(65%) sepia(13%) saturate(1176%) hue-rotate(181deg) brightness(98%) contrast(99%);
  --icon-grey: invert(33%) sepia(76%) saturate(65%) hue-rotate(33deg) brightness(80%) contrast(91%);
  --icon-grey-very-dark: invert(52%) sepia(100%) saturate(206%) hue-rotate(108deg) brightness(103%) contrast(105%);
  --icon-grey-mobile: invert(30%) sepia(90%) saturate(34%) hue-rotate(342deg) brightness(109%) contrast(102%);
  --icon-white: invert(0%) sepia(0%) saturate(47%) hue-rotate(359deg) brightness(77%) contrast(98%);
  --icon-white-light: invert(0%) sepia(0%) saturate(47%) hue-rotate(359deg) brightness(77%) contrast(98%) opacity(60%);
  --icon-red-pink: invert(64%) sepia(29%) saturate(1979%) hue-rotate(331deg) brightness(98%) contrast(102%);
  --icon-red: invert(53%) sepia(68%) saturate(3368%) hue-rotate(5deg) brightness(108%) contrast(91%);
  --icon-green: invert(24%) sepia(84%) saturate(827%) hue-rotate(281deg) brightness(106%) contrast(112%);
  --icon-green-flash: invert(11%) sepia(89%) saturate(386%) hue-rotate(291deg) brightness(110%) contrast(116%);
  --icon-green-twitter: invert(38%) sepia(13%) saturate(1617%) hue-rotate(230deg) brightness(70%) contrast(99%);
  --icon-private: invert(15%) sepia(91%) saturate(143%) hue-rotate(47deg) brightness(100%) contrast(96%);
  --icon-orange: invert(24%) sepia(51%) saturate(509%) hue-rotate(17deg) brightness(97%) contrast(99%);
  --icon-orange-dark: brightness(0) saturate(100%) invert(45%) sepia(82%) saturate(2722%) hue-rotate(9deg) brightness(103%) contrast(101%);
}

@keyframes _7aEym {
  0% {
    left: 0;
  }

  50% {
    left: 65%;
  }

  100% {
    left: 0;
  }
}

.globalLoaderBackground {
  z-index: 9999;
  background: var(--white);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.globalLoaderBackground .loader {
  margin-top: -40px;
  position: absolute;
}

.globalLoaderBackground .loader .logo {
  z-index: 5;
  width: 100%;
  height: 95px;
  position: relative;
}

.globalLoaderBackground .loader .logo .loading {
  background: var(--grey-sur);
  border-radius: 10px;
  width: 100px;
  height: 10px;
  position: relative;
}

.globalLoaderBackground .loader .logo .loading .caret {
  background: var(--grey-sur-hover);
  border-radius: 10px;
  width: 35%;
  height: 100%;
  animation: 1s infinite _7aEym;
  position: absolute;
}

.globalLoaderBackground .loader .logo .logo {
  float: left;
  background: var(--grad-sedestral);
  border-radius: 40%;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  display: flex;
  position: relative;
  top: 3px;
}

.globalLoaderBackground .loader .logo .logo .icon {
  width: 20px;
  height: 20px;
}

.globalLoaderBackground .loader .logo .text {
  color: var(--black);
  float: left;
  margin-left: 14px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 32px;
  position: relative;
  top: 4px;
}

.globalLoaderBackground .gradients .inboxGradient {
  background: var(--grad-inbox);
  width: 262px;
  height: 280px;
  position: fixed;
  top: 0;
  left: 0;
}

.globalLoaderBackground .gradients .blogGradient {
  background: var(--grad-blog);
  width: 138px;
  height: 240px;
  position: fixed;
  bottom: 0;
  left: 0;
}

.globalLoaderBackground .gradients .sellGradient {
  background: var(--grad-sell);
  width: 351px;
  height: 151px;
  position: fixed;
  bottom: 0;
  right: 120px;
}

.globalLoaderBackground .gradients .contactsGradient {
  background: var(--grad-contacts);
  width: 122px;
  height: 330px;
  position: fixed;
  top: 100px;
  right: -2px;
}

.globalLoaderBackground .gradients .publisherGradient {
  background: var(--grad-publisher);
  width: 370px;
  height: 120px;
  position: fixed;
  top: 0;
  right: calc(40% - 185px);
}

.globalLoaderBackground .gradients .gradient {
  width: calc(100% + 6px);
  height: calc(100% + 5px);
  position: absolute;
  top: -5px;
  left: -5px;
}

.globalLoaderBackground .gradients .background {
  opacity: 0;
  background-repeat: space;
  background-size: 18px;
}

.trAga {
  background: var(--white);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.trAga .JeHC0 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.trAga .JeHC0 .DzgHG {
  background: url("3d-help.e9efe075.svg") 0 0 / cover;
  width: 120px;
  height: 120px;
  margin-bottom: 35px;
  position: relative;
}

.trAga .DWtSk {
  text-align: center;
  width: 600px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 26px;
}

.pBWp- {
  width: calc(100% - 50px);
  max-width: 1400px;
  height: 100%;
  position: absolute;
}

@media screen and (width <= 950px) {
  .pBWp- {
    width: calc(100% - 30px);
  }
}

.VY0mt {
  gap: 70px;
  width: 100%;
  display: flex;
  position: absolute;
}

.VY0mt ._4CLfN {
  flex-direction: column;
  flex: 0 0 260px;
  width: 260px;
  height: calc(100vh - 50px);
  padding: 25px 0;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.VY0mt ._4CLfN .txXZ- {
  margin-bottom: 20px;
}

.VY0mt ._4CLfN .txXZ- ._49Pn- {
  display: flex;
}

.VY0mt ._4CLfN .txXZ- ._49Pn- .B1SAw {
  margin-bottom: 20px;
}

.VY0mt ._4CLfN .txXZ- ._49Pn- .la-iR {
  background: var(--black);
  color: var(--white);
  border-radius: 24px;
  height: fit-content;
  margin-left: auto;
  padding: 6px 10px;
  font-size: 14px;
  display: none;
}

.VY0mt ._4CLfN .txXZ- ._8alLl {
  color: var(--grey-text-dark-blog);
  font-size: 14px;
  line-height: 20px;
}

.VY0mt ._4CLfN .txXZ- .CfRgG {
  align-items: center;
  display: flex;
}

.VY0mt ._4CLfN .txXZ- .CfRgG .z-3pT {
  width: 16px;
  height: 16px;
  filter: var(--icon-grey-dark);
  background: url("black-search.59b0e4c4.svg") 0 0 / cover;
  margin-left: 10px;
  position: absolute;
}

.VY0mt ._4CLfN .txXZ- .CfRgG .ZlQIu {
  border-width: 1px;
  height: 34px;
  padding-left: 35px;
}

.VY0mt ._4CLfN .txXZ- .dlCM4 {
  background: var(--grey-sur);
  width: 50%;
  height: 1px;
  margin-top: 20px;
  position: relative;
}

.VY0mt ._4CLfN .itHRH {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  overflow: auto;
}

.VY0mt ._4CLfN .itHRH .TApCm {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.VY0mt ._4CLfN .itHRH .m9iL1 {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.VY0mt ._4CLfN .itHRH .m9iL1 li.Eu7vN .Li9F8 {
  border-left: 6px solid var(--black);
  padding-left: 8px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
}

.VY0mt ._4CLfN .itHRH .m9iL1 li .Li9F8 {
  cursor: pointer;
  align-items: center;
  height: 18px;
  margin-bottom: 15px;
  transition: all .1s;
  display: flex;
}

.VY0mt ._4CLfN .itHRH .m9iL1 li .Li9F8:hover {
  text-decoration: underline;
}

.VY0mt ._4CLfN .itHRH .m9iL1 li .Li9F8 .z-3pT img {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.VY0mt ._4CLfN .itHRH .m9iL1 li .Li9F8 .JleTv {
  font-size: 16px;
}

.VY0mt ._4CLfN .ERyEi {
  background: var(--black);
  width: 100%;
  color: var(--white);
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  height: 44px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 16px;
  display: flex;
}

.VY0mt ._4CLfN .sX5qr {
  border: 1px solid var(--grey-sur);
  border-radius: 30px;
  height: 42px;
  margin-bottom: 10px;
  overflow: hidden;
}

.VY0mt .HOF90 {
  justify-content: center;
  width: 100%;
  display: flex;
}

@media screen and (width <= 950px) {
  .VY0mt {
    display: block;
  }

  .VY0mt ._4CLfN {
    width: 100%;
    height: fit-content;
    padding: 15px 0;
    position: relative;
  }

  .VY0mt ._4CLfN .itHRH {
    flex-grow: unset;
  }

  .VY0mt ._4CLfN .itHRH .m9iL1 {
    display: flex;
  }

  .VY0mt ._4CLfN .itHRH .m9iL1 li {
    flex: none;
    margin-right: 15px;
  }

  .VY0mt ._4CLfN .ERyEi, .VY0mt ._4CLfN .sX5qr {
    display: none;
  }

  .VY0mt ._4CLfN .la-iR {
    display: block !important;
  }

  .VY0mt ._4CLfN .ZlQIu {
    font-size: 16px;
  }
}

.eSkuV {
  z-index: 99999999;
  background: var(--white);
  border-radius: 14px;
  height: fit-content;
  padding: 15px;
  position: fixed;
  box-shadow: 0 0 0 1px #00000008, 0 0 20px #0003;
}

.p28fd {
  z-index: 99999;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

._7C8wI {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

._7C8wI div[scrolltrack] {
  height: calc(100% - 20px);
  top: 10px;
  right: 5px;
}

._7C8wI .DSd4W {
  cursor: pointer;
  border-radius: 10px;
  padding: 6px;
}

._7C8wI .DSd4W:hover {
  background: var(--grey-sur);
}

._7C8wI .DSd4W .dhlLL {
  color: var(--black);
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 16px;
}

.ALwdj {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.ALwdj .c6RsJ {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.ALwdj .c6RsJ circle {
  stroke-width: 4px;
}

.ALwdj .c6RsJ svg {
  z-index: 2;
  width: 30px;
  height: 30px;
  animation: 2s linear infinite s24X6;
  position: absolute;
}

@keyframes s24X6 {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rRzOA {
  0% {
    stroke-dasharray: 1 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -124px;
  }
}

.ALwdj .c6RsJ svg .mKjxY {
  stroke: var(--black);
  stroke-width: 3px;
  animation: 1.5s ease-in-out infinite rRzOA;
  stroke-linecap: round !important;
}

._5QkTb {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

._5QkTb .XLEgw {
  width: 100%;
  position: relative;
}

._5QkTb .XLEgw .CZP6a {
  margin-top: 50px;
  position: relative;
}

._5QkTb .XLEgw .NwOdi {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  font-size: 50px;
  display: flex;
}

._5QkTb .XLEgw .lSeJc {
  text-align: center;
  width: 70%;
  color: var(--black);
  margin-bottom: 20px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 16px;
  position: relative;
  left: 15%;
}

._5QkTb .XLEgw ._6ilDe {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

._5QkTb .XLEgw ._6ilDe .z63-v {
  background: var(--grey-sur);
  cursor: pointer;
  color: var(--white);
  background: var(--grey-sur);
  border: none;
  border-radius: 30px;
  width: fit-content;
  margin-top: 25px;
  padding: 10px 30px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 16px;
  transition: all .1s;
  position: relative;
  color: var(--black) !important;
}

._5QkTb .XLEgw ._6ilDe .z63-v:hover {
  background: var(--grey-sur-hover);
}

._5QkTb .XLEgw ._6ilDe .z63-v.ygQJR {
  justify-content: center;
  align-items: center;
  display: flex;
  color: #0000 !important;
}

._5QkTb .XLEgw ._6ilDe .z63-v svg {
  height: 60%;
}

._5QkTb .XLEgw ._6ilDe .z63-v svg circle {
  stroke-width: 5px;
}

._5QkTb .XLEgw ._6ilDe .z63-v span {
  background: var(--red);
  color: var(--white);
  border-radius: 15px;
  padding: 1px 6px;
  font-size: 14px;
}

.eSdgY {
  cursor: pointer;
  border: var(--grey-sur) 2px solid;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  color: var(--black);
  border-radius: 10px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
  position: relative;
  overflow: hidden;
}

.eSdgY:hover {
  cursor: pointer;
  background: var(--grey-sur);
  border-color: #0000 !important;
}

.eSdgY.n4zL5 {
  pointer-events: none;
  background: var(--grey-sur);
}

.eSdgY .VGL49 {
  pointer-events: none;
  float: right;
  width: 12px;
  height: 12px;
  filter: var(--icon-black);
  background: url("black-bottom-bold.2bdbcf24.svg") 0 0 / cover;
  position: absolute;
  top: calc(50% - 6px);
  right: 8px;
}

.eSdgY .FqVw9 {
  border-radius: 6px;
  align-items: center;
  width: calc(100% - 42px);
  height: 100%;
  padding-left: 10px;
  display: flex;
}

@media screen and (width <= 480px) {
  .eSdgY {
    font-size: 16px !important;
  }
}

.urOy5 {
  position: relative;
  overflow: hidden;
}

.urOy5 .vtUSD {
  align-items: center;
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
}

.urOy5 .vtUSD:after {
  content: "";
  clear: both;
  display: block;
}

.urOy5 .vtUSD .fyn-j {
  filter: var(--icon-grey-dark);
  float: left;
  background: url("black-search.59b0e4c4.svg") 0 0 / cover;
  width: 14px;
  height: 14px;
  margin-top: 2px;
  margin-left: 4px;
  margin-right: 10px;
}

.urOy5 .vtUSD .X-B0T {
  float: left;
  width: calc(100% - 20px);
  padding: 5px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 13px;
  border: unset !important;
}

.urOy5 .hQUg8 {
  max-height: 100%;
}

.urOy5 .hQUg8[scrollvirtual] .cvRoC {
  width: calc(100% - 18px) !important;
}

.urOy5 .hQUg8[scrollvirtual] div[scrolltrack] {
  height: calc(100% - 16px);
  top: 8px;
  right: 0 !important;
}

.urOy5 .hQUg8 .Xh1u1 .cvRoC {
  cursor: pointer;
  width: calc(100% - 10px);
  color: var(--black);
  border-radius: 8px;
  align-items: center;
  margin-bottom: 2px;
  padding: 5px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 14px;
  display: flex;
}

.urOy5 .hQUg8 .Xh1u1 .cvRoC:hover, .urOy5 .hQUg8 .Xh1u1 .cvRoC.F--Ac {
  background: var(--grey-sur);
}

.urOy5 .hQUg8 .Xh1u1 .cvRoC.F--Ac:hover {
  background: var(--grey-sur-hover);
}

.urOy5 .hQUg8 .Xh1u1 .cvRoC.Kx9kb {
  display: block;
}

.urOy5 .hQUg8 .Xh1u1 .cvRoC .Dfg6z {
  color: var(--grey-text-dark);
  margin-top: 10px;
  font-family: medium, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 13px;
}

.urOy5 .hQUg8 .Xh1u1 .cvRoC div[buttonIcon] {
  float: left;
  width: 16px;
  height: 16px;
  margin-top: 1px;
  margin-left: 0;
  margin-right: 6px;
  position: relative;
}

.urOy5 .hQUg8 .Xh1u1 .cvRoC div[buttonAvatar] {
  float: left;
  background: var(--grey-sur);
  border-radius: 100%;
  width: 18px;
  height: 18px;
  margin-right: 6px;
  position: relative;
}

.urOy5 .hQUg8 .Xh1u1 .cvRoC div[buttonColor] {
  float: left;
  border-radius: 3px;
  width: 14px;
  height: 14px;
  margin-top: 1px;
  margin-right: 6px;
}

@media screen and (width <= 480px) {
  .urOy5 .cvRoC {
    width: calc(100% - 18px) !important;
    padding: 9px !important;
    font-size: 16px !important;
  }
}

._4nSsG {
  border: unset;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

._4nSsG .djvFN {
  width: 22px;
  height: 22px;
  filter: var(--icon-black);
  pointer-events: none;
  background: url("black-brightness.d1b3318d.svg") 0 0 / cover;
  flex: 0 0 22px;
  margin-left: auto;
  margin-right: 30px;
  position: absolute;
  right: 0;
}

.MXQAZ {
  width: 100%;
  padding: 25px 0;
}

.MXQAZ.ADtDL {
  height: calc(100% - 50px);
}

.MXQAZ .ajmvS {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
  display: flex;
}

.MXQAZ .ajmvS img {
  width: 65px;
  height: 65px;
  margin-bottom: 20px;
  position: relative;
}

.MXQAZ .ajmvS ._6CKqK {
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 16px;
}

.MXQAZ .ajmvS .tIKmq {
  color: var(--grey-text-dark);
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}

.MXQAZ .kSPYS {
  flex-wrap: wrap;
  gap: 25px 60px;
  display: flex;
}

.MXQAZ .kSPYS .kjLwW {
  cursor: pointer;
  border-radius: 16px;
  width: calc(50% - 30px);
  margin-bottom: 15px;
  transition: all 70ms;
  position: relative;
}

.MXQAZ .jFuq- {
  width: 100%;
  height: 100px;
  margin-top: 25px;
}

.MXQAZ .iK9KF {
  width: 100%;
  height: calc(100% - 160px);
}

@media screen and (width <= 950px) {
  .MXQAZ .kSPYS .kjLwW {
    width: 100%;
    margin-bottom: 50px;
  }
}

._9LD0z {
  border-radius: 15px;
  flex-direction: column;
  display: flex;
}

._9LD0z:hover .t8vgP ._7F38E {
  text-decoration: underline;
}

._9LD0z .ASPj0 {
  border: 1px solid var(--grey-sur);
  cursor: pointer;
  background: var(--grey-sur);
  color: #0000;
  border-radius: 4px;
  width: 100%;
  height: 276px;
  margin-bottom: 5px;
  overflow: hidden;
}

._9LD0z .ASPj0.-vlDw {
  justify-content: center;
  align-items: center;
  display: flex;
}

._9LD0z .ASPj0.-vlDw img {
  opacity: .2;
  width: 120px;
}

._9LD0z .ASPj0 img {
  width: 100%;
}

._9LD0z .t8vgP {
  margin-bottom: 10px;
}

._9LD0z .t8vgP ._7F38E {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 22px;
}

._9LD0z .t8vgP .ufUNQ {
  color: var(--grey-text-dark);
  -webkit-line-clamp: 6;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin: 0;
  font-family: medium, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 15px;
  display: -webkit-box;
  overflow: hidden;
}

._9LD0z .iSwtT {
  color: var(--grey-text);
  font-size: 12px;
}

._9LD0z .Hapun {
  margin-top: 20px;
}

._9LD0z .Hapun .C1aB9 {
  position: relative;
}

@media screen and (width <= 1010px) {
  ._9LD0z .ASPj0 {
    height: 220px;
  }
}

@media screen and (width <= 850px) {
  ._9LD0z .ASPj0 {
    height: 235px;
  }
}

._8kPsT {
  align-items: center;
  display: flex;
}

._8kPsT ._9WqY4 ._8hOSL {
  cursor: pointer;
  font-size: 14px;
}

.KHVLM {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.KHVLM span {
  margin-left: 5px;
  margin-right: 5px;
}

.KHVLM .-N69w {
  cursor: pointer;
  border: 1px solid #0000;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-right: 4px;
  font-family: semi-bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 13px;
  display: flex;
}

.KHVLM .-N69w:hover {
  background: var(--grey-sur);
}

.KHVLM .-N69w.waFEM {
  border-color: #0000;
  border: 1px solid var(--grey-sur);
}

.KHVLM .-N69w.S3hje .kudOA {
  background: url("black-left-bold.16972b44.svg") 0 0 / cover;
}

.KHVLM .-N69w.eg911 .kudOA {
  background: url("black-right-bold.3e588843.svg") 0 0 / cover;
}

.KHVLM .-N69w .kudOA {
  width: 10px;
  height: 10px;
}

._1iuv6 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

._1iuv6.KugNv .XLbL4 {
  overflow: hidden;
}

@keyframes subyq {
  0% {
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

._1iuv6.KugNv .XLbL4:after {
  content: "";
  background: linear-gradient(90deg, transparent, var(--white), transparent);
  width: 100%;
  height: 100%;
  animation: 1s infinite subyq;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

._1iuv6 .OuN5k {
  flex-wrap: wrap;
  gap: 25px 60px;
  width: 100%;
  height: 100%;
  display: flex;
}

._1iuv6 .OuN5k .XLbL4 {
  cursor: pointer;
  background: var(--grey-sur);
  border-radius: 4px;
  width: calc(50% - 30px);
  margin-bottom: 15px;
  transition: all 70ms;
  position: relative;
}

.nbBuo {
  width: 100%;
  max-width: 696px;
  padding: 25px 0;
}

.nbBuo .f6tu2 {
  height: calc(100vh - 50px);
  position: relative;
  overflow: hidden;
}

.nbBuo article {
  font-size: 15px;
}

.nbBuo article header {
  margin-bottom: 25px;
}

.nbBuo article header .ZuCOg {
  visibility: visible;
}

.nbBuo article header h1 {
  margin-top: 0;
  margin-bottom: 10px;
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
  font-size: 40px;
}

.nbBuo article header p {
  margin: 0;
}

.nbBuo article .GCjx- {
  word-break: break-word;
}

.nbBuo article .GCjx- img {
  cursor: pointer;
  border-radius: 10px;
}

.nbBuo article .GCjx- p {
  margin: 0;
}

.nbBuo article .GCjx- a {
  text-decoration: underline;
}

.nbBuo article .GCjx- ul, .nbBuo article .GCjx- ol {
  margin: 0;
  list-style-type: disc;
}

.nbBuo article .GCjx- ul:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: 14px;
}

.nbBuo article .GCjx- ul:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: 14px;
}

.nbBuo article .GCjx- ol:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: 14px;
}

.nbBuo article .GCjx- ol:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: 14px;
}

.nbBuo article .GCjx- ul:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 14px;
}

.nbBuo article .GCjx- ul:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 14px;
}

.nbBuo article .GCjx- ol:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 14px;
}

.nbBuo article .GCjx- ol:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 14px;
}

.nbBuo article .GCjx- strong, .nbBuo article .GCjx- b {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
}

.nbBuo article .GCjx- h1 {
  font-size: 42px;
}

.nbBuo article .GCjx- h2 {
  font-size: 32px;
}

.nbBuo article .GCjx- h3 {
  font-size: 26px;
}

.nbBuo article .GCjx- h4 {
  font-size: 20px;
}

.nbBuo article .GCjx- h5 {
  font-size: 18px;
}

.nbBuo article .GCjx- h6 {
  font-size: 16px;
}

.nbBuo article .GCjx- h1, .nbBuo article .GCjx- h2, .nbBuo article .GCjx- h3, .nbBuo article .GCjx- h4, .nbBuo article .GCjx- h5, .nbBuo article .GCjx- h6 {
  font-family: bold, Segoe UI Emoji, Montserrat, sans-serif;
}

@media screen and (width <= 480px) {
  .nbBuo article .GCjx- h1 {
    font-size: 32px;
  }

  .nbBuo article .GCjx- h2 {
    font-size: 24px;
  }

  .nbBuo article .GCjx- h3 {
    font-size: 20px;
  }

  .nbBuo article .GCjx- h4, .nbBuo article .GCjx- h5, .nbBuo article .GCjx- h6 {
    font-size: 16px;
  }

  .nbBuo article .GCjx- img {
    max-width: 100%;
    height: unset;
  }
}

.nbBuo article .GCjx- blockquote.ql-line-q {
  margin-top: 0 !important;
}

.nbBuo article .GCjx- blockquote {
  line-height: 22px;
  margin: 18px 0 0 .8ex !important;
}

.nbBuo article .GCjx- p {
  margin-top: 16px;
  line-height: 22px;
}

.nbBuo article .GCjx- li {
  line-height: 22px;
}

.nbBuo article .GCjx- ol, .nbBuo article .GCjx- ul {
  margin-top: 16px;
}

.nbBuo article footer {
  margin-bottom: 50px;
}

.nbBuo article .GaukA, .nbBuo article .BLpWm {
  color: var(--grey-text);
  margin-bottom: 15px;
  font-size: 13px;
}

.nbBuo article .GaukA a, .nbBuo article .BLpWm a {
  color: unset;
}

.nbBuo article .TxPhl {
  border: 1px solid var(--grey-sur);
  border-radius: 12px;
  width: fit-content;
  padding: 12px;
}

@media screen and (width <= 950px) {
  .nbBuo {
    margin-top: -20px;
    padding: 0;
  }
}

@media screen and (width <= 480px) {
  .nbBuo article header h1 {
    font-size: 28px;
  }
}

.RkNx2 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.RkNx2 .AwIK5 {
  width: 50%;
  margin-top: -10px;
  margin-bottom: 25px;
}

.RkNx2 .kAZAW {
  height: calc(100% - 115px);
  position: relative;
  overflow: hidden;
}

.ts9Ct {
  background: var(--grey-sur);
  border-radius: 15px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.ts9Ct.UNkWR {
  overflow: hidden;
}

@keyframes _6u5eI {
  0% {
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.ts9Ct.UNkWR:after {
  content: "";
  background: linear-gradient(90deg, transparent, var(--white), transparent);
  width: 100%;
  height: 100%;
  animation: 1s infinite _6u5eI;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.sH3ID {
  width: 100%;
  overflow: hidden;
}

.sH3ID.fvZUp {
  overflow: hidden;
}

@keyframes o8s08 {
  0% {
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.sH3ID.fvZUp:after {
  content: "";
  background: linear-gradient(90deg, transparent, var(--white), transparent);
  width: 100%;
  height: 100%;
  animation: 1s infinite o8s08;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.sH3ID .u-IXp {
  background: var(--grey-sur);
  border-radius: 14px;
  width: 100%;
  margin-top: 10px;
  position: relative;
}

.qfWjG .R-uQX {
  margin-bottom: 15px;
}

.qfWjG ._5Yx8v {
  gap: 10px;
  display: flex;
}

.qfWjG ._5Yx8v ._86FCv {
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  display: flex;
}

.qfWjG ._5Yx8v ._86FCv:hover {
  background: var(--grey-sur);
}

.qfWjG ._5Yx8v ._86FCv._2zf96 .vFnYD {
  background: url("linkedin-black.0ad9d825.svg") 0 0 / cover;
  width: 24px;
  height: 24px;
  margin-top: -2px;
}

.qfWjG ._5Yx8v ._86FCv.bdTDg .vFnYD {
  background: url("twitter-black.ba1e004b.svg") 0 0 / cover;
}

.qfWjG ._5Yx8v ._86FCv._-8gpX .vFnYD {
  background: url("facebook-black.a8b651e7.svg") 0 0 / cover;
  width: 22px;
  height: 22px;
}

.qfWjG ._5Yx8v ._86FCv.YUuRt .vFnYD {
  background: url("mail-black.5e405b74.svg") 0 0 / cover;
}

.qfWjG ._5Yx8v ._86FCv .vFnYD {
  width: 30px;
  height: 30px;
}

._4qp2g a {
  color: unset;
}

._4qp2g .v-Zrf {
  border: 1px solid var(--grey-sur);
  border-radius: 10px;
  align-items: center;
  margin-bottom: 15px;
  padding: 15px;
  font-size: 14px;
  display: flex;
}

._4qp2g .v-Zrf.KFONN:hover {
  text-decoration: underline;
}

._4qp2g .v-Zrf .o9-NJ {
  width: 12px;
  height: 12px;
  margin-right: 10px;
}

._4qp2g .v-Zrf .o9-NJ.oY1Ll {
  background: url("black-lineal-time.d4a4b708.svg") 0 0 / cover;
}

._4qp2g .v-Zrf .o9-NJ.CFLLo {
  background: url("black-left-arrow.a5746a87.svg") 0 0 / cover;
}

@media screen and (width <= 950px) {
  ._4qp2g .v-Zrf {
    display: none;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: medium, Segoe UI Emoji, Montserrat, sans-serif;
}

main {
  justify-content: center;
  width: 100%;
  min-height: 100%;
  display: flex;
  position: absolute;
  overflow-y: scroll;
}

input {
  background: var(--white-light);
  width: 100%;
  color: var(--black);
  border: 1px solid #eaeaea;
  border: var(--grey-sur) 2px solid;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: medium, Segoe UI Emoji, Montserrat, sans-serif;
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

input::-webkit-input-placeholder {
  color: var(--grey-text);
}

input::placeholder {
  color: var(--grey-text);
}

input:focus {
  outline: 0;
}

input[disabled] {
  border: var(--grey-sur-hover) 2px solid;
  background: var(--grey-sur);
}

input:focus {
  border-color: var(--grey-alpha);
}

a {
  color: var(--black);
  text-decoration: none;
}
/*# sourceMappingURL=blog.2d331322.css.map */
